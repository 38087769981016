<template>
  <nav class="navbar navbar-expand">
    <div class="page-container">
      <div class="navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-if="!loggedIn">
            <router-link class="nav-link" :to="{ name: 'Login' }">
              Login
            </router-link>
          </li>
          <li class="nav-item active align-bottom" v-if="canEdit">
            <router-link class="nav-link" :to="{ name: 'Create' }">
              Skapa nytt
            </router-link>
          </li>
          <li class="nav-item" v-if="user.isAdmin">
            <router-link class="nav-link" :to="{ name: 'Admin' }">
              Admin
              <span
                v-if="active_changes_count.count > 0"
                class="icon-button__badge"
                >{{ active_changes_count.count }}</span
              >
            </router-link>
          </li>
          <li class="nav-item" v-if="user.group === 'INTERMEDIARY'">
            <router-link class="nav-link" :to="{ name: 'Inbox' }">
              Inkorg
              <span
                v-if="active_changes_count.count > 0"
                class="icon-button__badge"
                >{{ active_changes_count.count }}</span
              >
            </router-link>
          </li>
          <li class="nav-item" v-if="loggedIn">
            <a href="#" @click="logout()" class="nav-link">Log out</a>
          </li>
        </ul>
        <span class="navbar-text">
          <strong
            v-if="fullname"
            class="welcome"
            style="color: #212529"
            @click="changePassword()"
          >
            <span>Välkommen {{ fullname }}</span>
          </strong>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
import { axiosBase } from "@/axios-api";

export default {
  name: "navbar",
  data() {
    return {
      active_changes_count: {},
      user: {},
      loggedIn: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/") {
        this.logout();
        this.getUser();
      } else if (from.path === "/") {
        this.getUser();
        this.getActiveChangesCount();
      } else if (to.path !== "/") {
        this.getActiveChangesCount();
      }
    },
  },
  computed: {
    fullname: function () {
      const firstName = this.user.first_name ?? "";
      const lastName = this.user.last_name ?? "";
      return firstName + " " + lastName;
    },
    isIntermediary: function () {
      return this.user.group === "INTERMEDIARY";
    },
    canEdit: function () {
      return this.user.canEdit;
    },
  },
  beforeMount() {},
  methods: {
    changePassword() {
      this.$router.push({ name: "Password" });
    },
    getUser() {
      // Retrieve user information and if user is logged in
      this.user = this.$store.getters.userDetail;
      this.loggedIn = this.$store.getters.isLoggedIn;
    },
    async getActiveChangesCount() {
      let changemaster = "";
      if (this.user.isAdmin) {
        changemaster = "?status=at_admin";
      } else if (this.user.group === "INTERMEDIARY") {
        changemaster = "?status=at_intermediary";
      }
      await axiosBase
        .get("api/v1/changemaster/count/" + changemaster)
        .then((response) => {
          this.active_changes_count = response.data;
        })
        .catch(() => {});
    },
    async logout() {
      await this.$store.dispatch("logout");
      await this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@media print {
  nav {
    display: none;
  }
}
nav {
  background-color: #0176ba;
}
.welcome {
  color: white !important;
  font-weight: 200 !important;
  cursor: pointer;
}
.welcome:hover span {
  display: none;
}
.welcome:hover:before {
  content: "Ändra Lösenord";
}
.col-md-6 {
  margin-bottom: 1.5rem;
}
.header {
  background-color: #0176ba;
  height: 56px;
  margin-top: 50px;
}

li a {
  padding: 6px 20px;
  background-color: white;
  color: #0176ba;
  margin-right: 8px;
  border: solid 1px #0176ba;
  border-radius: 6px;
  text-decoration: underline;
  box-shadow: white;
}

li a:hover {
  background-color: #0176ba;
  color: white;
  border: solid 1px;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background-color: #cccccc;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #333333;
  background-color: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;
}

.icon {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
.icon-button__badge {
  background-color: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  text-decoration: none;
  border-radius: 80%;
  margin-left: 5px;
  width: 25px;
  float: right;
}
</style>
