<script>
/* eslint-disable */
export default {
  props: ["field", "values"],
  emits: ["updatedValue"],
  methods: {
    onChange(e) {
      let value = e.target.value;
      if (value === "") value = null;
      if (!isNaN(value)) value = Number(value);
      this.field.value = value;
      this.$emit('updatedValue', this.field.value);
    },
  }
};
</script>
<template>
    <select
      v-model="field.value"
      :id="field.name"
      :disabled="field.disable || field.locked"
      :required="field.required"
      @change="onChange"
      v-bind:class="{ 
        'form-control': true,
        'is-invalid': !!field.invalid,
        'updated': !!field.updated,
        'awaiting-approval': !!field.awaitingApproval
      }"
    >
      <option
        class="buttonOne"
        v-for="item in values"
        :key="item.value"
        :value="item.value"
        :disabled="item.active !== undefined && !item.active"
      >
        {{ item.text }}
      </option>
    </select>
</template>
