<template>
  <header>
    <div class="page-container">
      <div class="OneContainer pt-3 pb-3">
        <router-link to="/main">
          <img
            class="logo"
            src="https://kommunassurans.se/wp-content/uploads/2023/11/Logotype-Kommunassurans-Forsakring-300x100-1.png"
          />
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "log",
};
</script>
<style>
nav {
  justify-content: end;
}

.menu-items {
  color: black;
  padding: 10px 20px;
  position: relative;
  text-align: right;
  border-bottom: 3px solid transparent;
}

.menu-items a {
  color: black;
  text-decoration: underline;
  margin-top: 40px;
}

.OneContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
</style>
