<template>
  <div class="dashboard">
    <Sidebar />

    <div class="content">
      <div id="show-table">
        <table id="ruta" class="table table-striped table-hover table.bordered">
          <thead>
            <tr class="header" scope="col">
              <th scope="col">Typ</th>
              <th scope="col">Ändringen avser</th>
              <th scope="col">Status</th>
              <th scope="col">Populärnamn</th>
              <th scope="col">Kommun</th>
              <th scope="col">Skapad av</th>
              <th class="text-right"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="change in pending_changes" v-bind:key="change.id">
              <td>{{ returnType(change.type) }}</td>
              <td>{{ change.reason }}</td>
              <td>{{ returnStatus(change.status) }}</td>
              <td>{{ change.insurance_object.name }}</td>
              <td>
                {{ change.insurance_object.municipality.name }}
              </td>
              <td>{{ change.created_by.username }}</td>
              <td class="text-right">
                <router-link
                  :to="{
                    name: 'Approve',
                    params: { id: change.insurance_object.id },
                  }"
                  class="btn btn-primary badge bg-primary float-right"
                  id="inkorgKnapp"
                >
                  <i class="far fa-eye"></i>Display</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosBase } from "@/axios-api";
import Sidebar from "../components/Sidebar.vue";
export default {
  name: "Inbox",
  components: {
    Sidebar,
  },

  data() {
    return {
      user: {},
      pending_changes: [],
    };
  },
  beforeMount() {
    this.getPendingChanges();
  },
  methods: {
    returnStatus(status) {
      if (status === "at_intermediary") {
        return "Hos Förmedlare";
      } else if (status === "at_admin") {
        return "Hos Admin";
      }
      return "";
    },
    returnType(type) {
      if (type === "created") {
        return "Nytt objekt";
      } else if (type === "changed") {
        return "Ändring";
      }
      return "";
    },
    async getPendingChanges() {
      await axiosBase
        .get("api/v1/changemaster/active")
        .then((response) => {
          this.pending_changes = response.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .dashboard {
    height: 100vh;
  }
}
@media (min-width: 1400px) {
  .dashboard {
    height: 83vh;
  }
}
.header {
  position: sticky;
  z-index: 3;
  color: white;
  top: -2px;
  font-size: 15px;
}
.dashboard {
  display: grid;
  grid-template-columns: 1fr 4fr;
  background-color: black;
  width: 100vw;
}

.content {
  background-color: white;
  border-radius: 20px;
  margin: 6px 6px 6px 0;
}
#ruta {
  max-width: 80%;
  margin-top: 0 !important;
}

.inkorg {
  padding-top: 3px;
}

#inkorgKnapp {
  padding: 10px 20px;
}
</style>
