export const insuranceObjectFields = {
  id: {
    name: "id",
    type: "number",
    disable: true,
  },
  insurance_company: {
    name: "insurance_company",
    type: "text",
    disable: true,
    value: "Kommunassurans Försäkring AB",
  },
  municipality: {
    name: "municipality",
    required: true,
    type: "select",
  },
  property_owner: {
    name: "property_owner",
    required_nonadmin: true,
    warning_admin: true,
    type: "text",
    length: 100,
  },
  management: {
    name: "management",
    type: "text",
    length: 40,
  },
  internal_municipality_id: {
    name: "internal_municipality_id",
    type: "text",
    length: 40,
  },
  name: {
    name: "name",
    type: "text",
    required: true,
    length: 120,
  },
  property_designation: {
    name: "property_designation",
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 100,
  },
  street_name: {
    name: "street_name",
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 100,
  },
  street_number: {
    name: "street_number",
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 20,
  },
  zip_code: {
    name: "zip_code",
    warning_admin: true,
    required_nonadmin: true,
    type: "number",
    length: 5,
  },
  city: {
    name: "city",
    warning_admin: true,
    required_nonadmin: true,
    type: "text",
    length: 255,
  },
  coordinate_lat: {
    required_nonadmin: true,
    type: "number",
  },
  coordinate_lon: {
    required_nonadmin: true,
    type: "number",
  },
  group: {
    name: "group",
    type: "text",
    length: 255,
  },
  official_id: {
    name: "official_id",
    type: "text",
    length: 25,
  },
  external_id: {
    name: "external_id",
    warning_nonadmin: true,
    type: "text",
    length: 25,
  },
  electrical_id: {
    name: "electrical_id",
    type: "text",
    length: 40,
  },
  category_external: {
    name: "category_external",
    required_nonadmin: true,
    warning_admin: true,
    type: "select",
  },
  category_internal: {
    name: "category_internal",
    type: "select",
  },
  specification: {
    name: "specification",
    required_nonadmin: true,
    warning_admin: true,
    type: "text",
    length: 255,
  },
  calculated_value: {
    name: "calculated_value",
    required_nonadmin: true,
    type: "text",
    currency: true,
  },
  calculation_method: {
    name: "calculation_method",
    required_nonadmin: true,
    type: "text",
    length: 40,
  },
  calculation_date: {
    name: "calculation_date",
    required_nonadmin: true,
    type: "date",
    length: 40,
  },
  calculated_value_content: {
    name: "calculated_value_content",
    type: "text",
    currency: true,
  },
  calculated_value_total: {
    name: "calculated_value_total",
    type: "text",
    currency: true,
  },
  requested_form_of_insurance: {
    name: "requested_form_of_insurance",
    required_nonadmin: true,
    type: "select",
    isVisible: function (ctx) {
      return (
        ctx.new ||
        ctx.notApproved ||
        ctx.isIntern ||
        !ctx.item["form_of_insurance"]
      );
    },
  },
  form_of_insurance: {
    name: "form_of_insurance",
    warning_admin: true,
    type: "select",
    isVisible: function (ctx) {
      return (
        (ctx.hasFormOfInsurance && !ctx.new && !ctx.notApproved) || ctx.isIntern
      );
    },
  },
  insurance_amount: {
    name: "insurance_amount",
    type: "text",
    length: 40,
    currency: true,
    isVisible: function (ctx) {
      return (
        ctx.isIntern ||
        ctx.formOfInsurance == "HV" ||
        ctx.formOfInsurance == "FR"
      );
    },
  },
  first_risk: {
    name: "first_risk",
    type: "text",
    currency: true,
    isVisible: function (ctx) {
      return ctx.isIntern || ctx.formOfInsurance == "FR";
    },
  },
  created_on: {
    name: "created_on",
    disable: true,
    placeholder: new Date().toLocaleDateString("sv-SE"),
    value: new Date().toLocaleDateString("sv-SE"),
    type: "date",
  },
  created_by: {
    name: "created_by",
    disable: true,
    type: "text",
  },
  requested_insurance_from_date: {
    name: "requested_insurance_from_date",
    required_nonadmin: true,
    type: "date",
    value: new Date().toLocaleDateString("sv-SE"),
  },
  insurance_from_date: {
    name: "insurance_from_date",
    warning_admin: true,
    disable_nonadmin: true,
    type: "date",
    placeholder: "Ej försäkrad",
  },
  uw: {
    name: "uw",
    required_admin: true,
    type: "text",
    length: 255,
  },
  uw_decision_date: {
    name: "uw_decision_date",
    type: "date",
    required_admin: true,
    disable_nonadmin: true,
  },
  requested_insurance_to_date: {
    name: "requested_insurance_to_date",
    type: "date",
  },
  insurance_to_date: {
    name: "insurance_to_date",
    disable_nonadmin: true,
    type: "date",
  },
  adjusted_premium: {
    name: "adjusted_premium",
    disable_nonadmin: true,
    type: "date",
  },
  tenant: {
    name: "tenant",
    type: "text",
    length: 40,
  },
  build_year: {
    name: "build_year",
    warning_admin: true,
    required_nonadmin: true,
    type: "number",
    length: 4,
  },
  latest_renovation: {
    name: "latest_renovation",
    type: "number",
    length: 4,
  },
  cultural_protection_type: {
    name: "cultural_protection_type",
    warning_admin: true,
    required_nonadmin: true,
    type: "select",
    length: 255,
  },
  facility_class: {
    name: "facility_class",
    required_nonadmin: true,
    type: "select",
    support: [
      {
        value: "Byggnadsklass 1",
        description:
          "Byggnad som har alla byggnadsdelar (ytterväggar, stomme, tak, mellanbotten) av sten eller betong, eller byggnad som har två eller flera mellanbottnar och alla av sten eller betong, oavsett utförande av ytterväggar, stomme och tak.",
      },
      {
        value: "Byggnadsklass 2",
        description:
          "Byggnad som har alla ytterväggar av sten eller betong, men där byggnadssättet i övrigt avviker från byggnadsklass 1 ovan och innehåller byggnadsdelar (stomme, tak, mellanbotten) av stål, limträ, plåt, trä eller plast.",
      },
      {
        value: "Byggnadsklass 3",
        description:
          "Byggnad som har yttervägg av trä eller plast som är utvändigt skyddade med revetering eller annan svårantändlig beklädnad. Övriga byggnadsdelar (stomme, tak, mellanbotten) är till övervägande delar är av trä eller plast.",
      },
      {
        value: "Byggnadsklass 4",
        description:
          "Byggnad som har yttervägg och ytterbeklädnad av brännbar konstruktion som trä eller plast och där även övriga byggnadsdelar (stomme, tak, mellanbotten) till övervägande delar är av trä eller plast.",
      },
    ],
  },
  gross_floor_area: {
    name: "gross_floor_area",
    warning_admin: true,
    required_nonadmin: true,
    type: "number",
  },
  basement_included: {
    name: "basement_included",
    type: "select",
  },
  fire_alarm: {
    name: "fire_alarm",
    required_nonadmin: true,
    type: "select",
    support: [
      {
        value: "Nej",
        description: "Brandlarm saknas",
      },
      {
        value: "B",
        description:
          "Avser ett heltäckande brandlarm enligt regelverket SBF 110 vilket också innebär att det är vidarekopplat till larmcentral/räddningstjänst",
      },
      {
        value: "BA",
        description:
          "Avser ett brandlarm installerat enligt regelverket SBF 110, bilaga A. Larmet ska därmed vara vidarekopplat till larmcentral.",
      },
      {
        value: "+",
        description:
          "Ovanstående beteckningar B och BA kan kompletteras med ett + om larminstallationen är mer omfattande än regelverket, det kan exempelvis vara fler detektorer än vad regelverket kräver eller att en takfotskabel installerats.",
      },
      {
        value: "-",
        description:
          "Ovanstående beteckningar B och BA kan kompletteras med ett – om avsteg gjorts i förhållande till regelverket som exempelvis färre detektorer.",
      },
      {
        value: "U",
        description:
          "Indikerar att det finns ett utrymningslarm installerat i byggnaden. Med utrymningslarm avses anläggning för att åstadkomma trygg och säker utrymning. Start av anläggningen sker automatiskt vid rökdetektering och manuellt med tryckknapp. Larmet är inte vidarekopplat till larmcentral eller räddningstjänst.",
      },
    ],
  },
  burglar_alarm: {
    name: "burglar_alarm",
    required_nonadmin: true,
    type: "select",
  },
  burglar_alarm_type: {
    name: "burglar_alarm_type",
    type: "text",
    disable: true,
  },
  fire_protection: {
    name: "fire_protection",
    required_nonadmin: true,
    type: "select",
  },
  external_note: {
    name: "external_note",
    type: "textarea",
    rows: 5,
  },
  internal_note: {
    name: "internal_note",
    type: "textarea",
    rows: 5,
  },
  premium: {
    name: "premium",
    type: "text",
    currency: true,
  },
  insurance_company_information: {
    name: "insurance_company_information",
    posts: [],
    subTitle: "Lägg till upplysning till försäkringsbolag",
    type: "textarea",
  },
  specific_terms: {
    name: "specific_terms",
    subTitle: "Lägg till villkor för försäkringsskydd",
    posts: [],
    disable_nonadmin: true,
    type: "textarea",
  },
  fire_alarm_type: {
    name: "fire_alarm_type",
    type: "text",
  },
  other_responsible: {
    name: "other_responsible",
    type: "text",
  },
  other_responsible_email: {
    name: "other_responsible_email",
    type: "email",
  },
  solar_cells: {
    name: "solar_cells",
    required_nonadmin: true,
    type: "select",
  },
  energy_storage: {
    name: "energy_storage",
    required_nonadmin: true,
    type: "select",
  },
  number_of_floors: {
    name: "number_of_floors",
    warning_admin: true,
    type: "number",
  },
};
