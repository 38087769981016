<template>
  <div class="crateContainer">
    <div v-if="hasPendingChanges" class="change-button-container">
      <b-button
        v-if="!isUpdate"
        variant="danger"
        @click="() => $emit('goToUpdate')"
        class="gul"
        id="create"
        >Ändra förfrågan
      </b-button>
      <b-button
        v-if="isUpdate"
        variant="success"
        @click="continueToModal()"
        id="godkann"
        class="grön"
        >Godkänn förfrågan med dina nya ändringar
      </b-button>
      <b-button
        v-else-if="isApprove"
        variant="success"
        @click="continueToModal()"
        class="grön"
        id="godkann"
        >Godkänn förfrågan
      </b-button>
    </div>
    <div v-else>
      <div class="change-button-container" v-if="!show">
        <div>
          <a
            id="edit"
            class="btn btn-primary"
            role="button"
            @click="(e) => toggleFooterAndValidate(e)"
            >{{ toggleButtonText }}</a
          >
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div
            class="collapse multi-collapse"
            v-bind:class="{ show: show }"
            id="makechangerequest"
          >
            <div class="card card-body" id="SkickaChangeFörmedlare">
              <form @submit.prevent="continueToModal()" class="ändringTop">
                <div class="row mt-1">
                  <div class="form-Mortar col-10">
                    <label class="color">Ändringen avser</label>
                    <select
                      v-model="typeOfChange"
                      class="form-Mottagare"
                      id="typeofchange"
                      required="true"
                    >
                      <option value="Nyanskaffning">Nyanskaffning</option>
                      <option value="Fastighetsbildning">
                        Fastighetsbildning
                      </option>
                      <option value="Ombyggnad">Ombyggnad</option>
                      <option value="Tillbyggnad">Tillbyggnad</option>
                      <option value="Förändring av verksamhet">
                        Förändring av verksamhet
                      </option>
                      <option value="Avyttring (försäljning)">
                        Avyttring (försäljning)
                      </option>
                      <option value="Beslut om rivning">
                        Beslut om rivning
                      </option>
                      <option value="Rivning genomförd">
                        Rivning genomförd
                      </option>
                      <option value="Objektet totalskadat">
                        Objektet totalskadat
                      </option>
                      <option value="Övrigt (skriv i noteringar)">
                        Övrigt (skriv i noteringar)
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <b-button
                      v-if="show"
                      @click="(e) => closeExpansion(e)"
                      class="close-container-button float-end"
                      ><i class="fa fa-solid fa-times fa-lg"></i>
                    </b-button>
                  </div>
                </div>

                <div v-if="user.roles.municipality" class="form-Mortar">
                  <label class="color">Skicka begäran till</label>
                  <select
                    v-model="byIntermediary"
                    :disabled="!hasIntermediary"
                    class="form-Mottagare"
                    id="intermediary"
                    required="true"
                  >
                    <option :value="true">Skicka till förmedlare</option>
                    <option :value="false">Skicka till admin</option>
                  </select>
                </div>

                <button
                  v-if="showSubmitButton"
                  type="submit"
                  class="btn btn-primary"
                  id="requestchange"
                  :disabled="!isValid || typeOfChange == ''"
                >
                  {{ submitButtonText }}
                </button>
                <i v-else id="NoChangeInput">Inga ändringar gjorda</i>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <confirmation-modal
    v-if="showModal"
    :body="modalBodyText"
    :header="modalHeaderText"
    @close-modal-event="modalClosed"
  />
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal.vue";
export default {
  props: [
    "isNew",
    "hasPendingChanges",
    "isUpdate",
    "isModified",
    "hasIntermediary",
    "isValid",
    "isApprove",
  ],
  emits: ["doValidation", "execute", "goToUpdate"],
  components: { ConfirmationModal },
  data() {
    return {
      confirmed: false,
      byIntermediary: false,
      typeOfChange: "",
      showModal: false,
      show: false,
      user: this.$store.getters.userDetail,
    };
  },
  methods: {
    closeExpansion(e) {
      e.preventDefault();
      this.show = false;
    },
    toggleFooterAndValidate(e) {
      e.preventDefault();
      this.$emit("doValidation", true);
      setTimeout(() => {
        this.show = this.isValid;
      }, 200);
    },
    continueToModal() {
      this.$emit("doValidation", false);
      setTimeout(() => {
        if (this.isValid) this.showModal = true;
        else this.show = false;
      }, 200);
    },
    modalClosed(value) {
      if (value)
        this.$emit("execute", {
          byIntermediary: this.byIntermediary,
          typeOfChange: this.typeOfChange,
        });
      this.showModal = false;
    },
  },
  computed: {
    toggleButtonText() {
      if (this.isNew && this.user.roles.kommunassurans) return "Skapa objekt";
      if (this.isNew) return "Skicka begäran om nytt objekt";
      if (this.user.roles.kommunassurans) return "Gör Ändring";
      return "Begär ändring";
    },
    showSubmitButton() {
      return this.isNew || this.isModified;
    },
    submitButtonText() {
      if (this.isNew && this.user.roles.kommunassurans) return "Skapa objekt";
      if (this.isNew) return "Skicka begäran om nytt objekt";
      if (this.user.roles.kommunassurans) return "Ändra objekt";
      return "Skicka ändringsbegäran";
    },
    modalHeaderText() {
      if (this.hasPendingChanges) return "Bekräfta godkännande";
      if (this.isNew && this.user.roles.kommunassurans) return "Skapa objekt";
      if (this.isNew) return "Bekräfta begäran";
      if (this.user.roles.kommunassurans) return "Ändra objekt";
      return "Begär ändring";
    },
    modalBodyText() {
      if (this.hasPendingChanges)
        return "Vänligen bekräfta godkännande av objektet";
      if (this.isNew && this.user.roles.kommunassurans)
        return "Vänligen bekräfta att objektet skall skapas";
      if (this.isNew) return "Vänligen bekräfta begäran att skapa objekt";
      if (this.user.roles.kommunassurans)
        return "Vänligen bekräfta ändring av objektet";
      return "Vänligen bekräfta begäran att ändra objektet";
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.col {
  transition: height 4s;
}
.modal {
  display: block;
}
.modal-content {
  border-color: #0176ba;
  border-width: medium;
  margin-top: 37%;
}

.change-button-container {
  height: 95px;
  margin: 0;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.close-container-button[aria-expanded="false"] {
  display: none;
}

.close-container-button {
  font-weight: 400;
  background-color: white;
  font-weight: bold;
  border: none;
  color: #0176ba;
  margin-right: 30px;
}

.close-container-button:focus,
.close-container-button:hover,
.close-container-button:active {
  background-color: white;
  color: #0176ba;
}

#create {
  margin-left: 30px;
}

.createContainer {
  border-top: 1px solid #ced4da;
  background-color: white;
  position: sticky;
  bottom: 0;
}

label {
  font-weight: 600;
  color: #0176ba;
}
</style>
