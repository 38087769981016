<script>
import _ from "lodash";
import { insuranceObjectFields } from "../components/InsuranceObjectFields";
import customFormEventHandler from "@/components/customFormEventHandler.js";
import { validateInputMixin } from "@/components/mixins/validate-input.mixin";
import { axiosBase } from "@/axios-api";
import { useToast } from "vue-toastification";

export default {
  name: "InsuranceInputBase",
  mixins: [validateInputMixin],
  computed: {
    isMunicipality: function () {
      return this.user.roles.municipality;
    },
    isIntern: function () {
      return this.user.roles.kommunassurans;
    },
    hasFormOfInsurance: function () {
      return this.formList.form_of_insurance.value !== null;
    },
    formOfInsurance: function () {
      let form_of_insurance = "";
      if (this.formList.form_of_insurance) {
        const id = this.formList.form_of_insurance.value
          ? this.formList.form_of_insurance.value
          : this.formList.requested_form_of_insurance.value;

        const attr = this.attributes.find((x) => x.value == id);
        if (attr) form_of_insurance = attr.key;
      }
      return form_of_insurance;
    },
  },
  data() {
    return {
      modal_body: "",
      modal_header: "",
      byIntermediary: false,
      user: this.$store.getters.userDetail,
      submitForm: {},
      changeHandler: new customFormEventHandler(),
      formList: _.cloneDeep(insuranceObjectFields),
      attributes: [],
      toast: null,
      itemIntermediary: 0,
      selectOptions: {
        yes_no: [
          { value: "JA", text: "JA" },
          { value: "NEJ", text: "NEJ" },
        ],
        municipalities: [],
      },
      isValid: false,
      coord: {
        lat: 0,
        lon: 0,
      },
    };
  },
  methods: {
    initBase() {
      this.toast = useToast();
    },
    validate(doWarning) {
      if (doWarning) this.validateWarning(this.formList);
      return this.validateError(this.formList);
    },
    ensureDefaultValuesAreSubmitted() {
      if (this.$route.name === "Create") {
        for (let key in this.formList) {
          let value = this.formList[key].value;
          if (!this.$helpers.inDict(key, this.submitForm)) {
            this.submitForm[key] = value;
          }
        }
      }
    },
    updated(key, new_value) {
      this.updatePropertyValue(key, new_value);
      this.changeHandler.handleEvent("update", this.formList);
    },
    updatePropertyValue(key, new_value) {
      this.formList[key].invalid = !this.validateField(this.formList[key]);
      this.formList[key].updated = !!new_value;
      if (this.formList[key].updated) {
        this.submitForm[key] = new_value;
      } else if (
        this.$helpers.inDict(key, this.submitForm) &&
        this.submitForm[key] !== new_value
      ) {
        delete this.submitForm[key];
      }
    },
    async sendRequest(props) {
      await axiosBase({
        url: `api/v1/insuranceobject/${props.id ? props.id + "/" : ""}`,
        method: props.id ? "patch" : "post",
        data: this.submitForm,
      })
        .then((response) => {
          if (props.sendEmail) this.sendMail(response.data.id);
          this.toast.success(props.successMessage);
          this.$router.push({
            name: "Details",
            params: { id: response.data.id },
          });
        })
        .catch((error) => {
          if (
            error.response.status === 400 &&
            error.response.data.non_field_errors
          ) {
            this.toast.error(
              "Det finns redan ett försäkrat objekt med samma byggnadsnamn. Detta måste vara unikt."
            );
            this.formList.name.invalid = true;
          } else {
            this.toast.error("Servern returnerade ett felmeddelande");
          }
        });
    },
    async sendMail(id) {
      await axiosBase
        .post(`api/v1/insuranceobject/${id}/send_mail/`, {
          changeMaster: 0,
        })
        .catch(() => this.toast.error("Lyckades inte att skicka mailet."));
    },
    async getLabels() {
      await axiosBase.get("api/v1/descriptions/").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          this.formList[response.data[i].key].label = response.data[i].label;
          this.formList[response.data[i].key].help = response.data[i].help;
        }
      });
    },
    async getAttributes() {
      const mapData = (item) => {
        return {
          value: item.id.toString(),
          key: item.value,
          text: item.value.concat(" - ", item.description),
          active: item.active,
          default: item.default_flag,
        };
      };

      await axiosBase.get("api/v1/attributes/").then((response) => {
        const groups = Object.groupBy(
          response.data,
          ({ field_category }) => field_category
        );

        for (let key in groups)
          this.selectOptions[key] = groups[key].map(mapData);
        this.attributes = response.data.map(mapData);
      });
    },
    async getMunicipalities() {
      await axiosBase.get("api/v1/municipality/").then((response) => {
        this.selectOptions.municipalities = response.data.map((x) => {
          return {
            value: x.id,
            text: x.name,
            intermediaries: x.intermediaries,
          };
        });
      });
    },
    setIntermediary() {
      this.itemIntermediary = 0;
      const muni = this.selectOptions.municipalities.find(
        (x) => x.value === this.formList.municipality.value
      );
      if (muni && muni.intermediaries.length > 0)
        this.itemIntermediary = muni.intermediaries[0];
    },
    hasSelectedOptionKey(field, key) {
      const currentId = this.submitForm[field] ?? this.formList[field].value;
      if (currentId > 0) {
        const attr = this.attributes.find((x) => x.value == currentId);
        if (attr) return attr.key === key;
      }
      return false;
    },
    getCoordinates(value) {
      this.new_coord = value;
    },
    updateCoordinates() {
      if (!this.new_coord) {
        this.toast.info("Vänligen ange en punkt i kartan att hämta");
      } else {
        this.formList.coordinate_lat.value = this.new_coord.lat;
        this.formList.coordinate_lon.value = this.new_coord.lon;
        this.updatePropertyValue(
          "coordinate_lat",
          this.formList.coordinate_lat.value
        );
        this.updatePropertyValue(
          "coordinate_lon",
          this.formList.coordinate_lon.value
        );
      }
    },
  },
};
</script>
