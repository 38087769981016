import { useToast } from "vue-toastification";

export const validateInputMixin = {
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    validateError(items) {
      let errorFields = [];
      for (let key in items) {
        if (!this.validateField(items[key])) {
          items[key].invalid = true;
          errorFields.push(items[key].label);
        }
      }
      if (errorFields.length)
        this.toast.error(
          "Rätta till fält som saknar giltigt värde:\n" +
            errorFields.join("\n"),
          {
            id: "validate-error",
            timeout: false,
          }
        );
      else this.toast.dismiss("validate-error");
      return errorFields.length === 0;
    },
    validateWarning(items) {
      let errorFields = [];
      for (let key in items) {
        if (items[key].warning && !items[key].value) {
          errorFields.push(items[key].label);
        }
      }
      if (errorFields.length)
        this.toast.warning(
          "Följande fält saknar data:\n" + errorFields.join("\n"),
          { id: "validate-warning", timeout: false }
        );
      else this.toast.dismiss("validate-warning");
      return errorFields.length === 0;
    },
    validateField(field) {
      if (field.isVisible && !field.isVisible(this.ctx)) return true;
      const emailTest =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (field.required && !field.value) return false;
      if (field.type == "email" && field.value && !emailTest.test(field.value))
        return false;
      return true;
    },
  },
};
