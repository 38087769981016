export default class customFormEventHandler {
  constructor() {
    this.handlers = [];
    this.initDefaultHandlers();
  }
  setUser(user) {
    this.user = user;
  }

  register(method, event) {
    this.handlers.push({ event: event, execute: method });
  }

  initDefaultHandlers() {
    this.register(this.set_RequestedInsuranceToDate_Placeholder, "initialize");
    this.register(this.set_UW_Value, "initialize");
    this.register(this.set_UWDecisionDate_Value, "initialize");
    this.register(this.set_RequestedInsuranceToDate_Placeholder, "update");
    this.register(this.set_InsuranceToDate_Placeholder, "initialize");
    this.register(this.set_InsuranceToDate_Placeholder, "update");
    this.register(this.set_CreatedBy_Placeholder, "initialize");
    this.register(this.set_DynamicPropertyValues_ForAdmin, "initialize");
    this.register(this.set_DynamicPropertyValues_ForUser, "initialize");
  }

  handleEvent(event, item) {
    const ctx = { item: item, user: this.user };
    this.handlers
      .filter((x) => x.event === event)
      .forEach((x) => x.execute(ctx));
  }

  set_RequestedInsuranceToDate_Placeholder(ctx) {
    ctx.item.requested_insurance_to_date.placeholder = ctx.item
      .requested_insurance_from_date.value
      ? "Tillsvidare"
      : "";
  }
  set_UW_Value(ctx) {
    if (ctx.user.roles.kommunassurans) ctx.item["uw"].value = ctx.user.username;
  }
  set_UWDecisionDate_Value(ctx) {
    if (ctx.user.roles.kommunassurans)
      ctx.item["uw_decision_date"].value = new Date().toLocaleDateString(
        "sv-SE"
      );
  }
  set_DynamicPropertyValues_ForAdmin(ctx) {
    if (!ctx.user.roles.kommunassurans) return;
    for (let key in ctx.item) {
      if (ctx.item[key].warning_admin) ctx.item[key].warning = true;
      if (ctx.item[key].required_admin) ctx.item[key].required = true;
    }
  }
  set_DynamicPropertyValues_ForUser(ctx) {
    if (ctx.user.roles.kommunassurans) return;
    for (let key in ctx.item) {
      if (ctx.item[key].required_nonadmin) ctx.item[key].required = true;
      if (ctx.item[key].disable_nonadmin) ctx.item[key].disable = true;
      if (ctx.item[key].warning_nonadmin) ctx.item[key].warning = true;
    }
  }
  set_InsuranceToDate_Placeholder(ctx) {
    ctx.item.insurance_to_date.placeholder = ctx.item.insurance_from_date.value
      ? "Tillsvidare"
      : "Ej försäkrad";
  }
  set_CreatedBy_Placeholder(ctx) {
    ctx.item.created_by.placeholder = ctx.user.username;
  }
}
