import axios from "axios";

export const refreshPageMixin = {
  data() {
    return {
      currentHash: "{{POST_BUILD_ENTERS_HASH_HERE}}",
      hashChanged: false,
      newHash: "",
    };
  },
  mounted() {
    if (process.env.NODE_ENV === "production")
      this.initVersionCheck("/version.json");
  },
  methods: {
    initVersionCheck(url, frequency = 1000 * 60 * 2) {
      setInterval(() => {
        this.checkVersion(url);
      }, frequency);
    },
    async checkVersion(url) {
      const fileResponse = await axios
        .create({
          headers: {
            "Content-type": "application/json",
          },
        })
        .get(url + "?t=" + new Date().getTime());

      this.newHash = fileResponse.data.hash;
      this.hashChanged = this.hasHashChanged(this.newHash);
    },
    hasHashChanged(newHash) {
      if (!this.currentHash) return true;

      return this.currentHash !== newHash;
    },
    reloadApp() {
      this.currentHash = this.newHash;
      window.location.reload();
    },
  },
};
