<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Uppdatera beskrivning</h5>
          </div>
          <div class="modal-body">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                label="Name"
                label-for="name-input"
                invalid-feedback="Name is required"
                :state="editState"
              >
                <b-form-input
                  id="name-input"
                  v-model="editDescription"
                  :state="editState"
                  required
                ></b-form-input>
                <b-form-checkbox
                  name="checkbox-default-flag"
                  id="checkbox-default-flag"
                  v-model="editDefaultFlag"
                  :checked="this.default_flag"
                  :state="editState"
                >
                  Defaultvärde för optionslista
                </b-form-checkbox>
              </b-form-group>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveChanges">
              Spara
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelModal"
            >
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["description", "default_flag"],
  data() {
    return {
      editDescription: "",
      editState: null,
      editDefaultFlag: null,
    };
  },
  mounted() {
    this.editDescription = this.description;
    this.editDefaultFlag = this.default_flag;
  },
  methods: {
    cancelModal() {
      this.$emit("cancel-event");
    },
    saveChanges() {
      this.$emit("save-event", {
        description: this.editDescription,
        default_flag: this.editDefaultFlag,
      });
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}
.modal-content {
  border-color: #0176ba;
  border-width: medium;
  margin-top: 37%;
}
.form-check {
  display: flex;
  align-items: center;
}

.form-check-input {
  display: flex;
  vertical-align: center;
}
</style>
