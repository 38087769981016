<template>
  <div class="page-container">
    <div class="page-title">
      <h1>{{ title }}</h1>
    </div>
    <div class="col-xs-6 form-background">
      <form @submit.prevent class="needs-validation" novalidate>
        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-1"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-1"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Avtalsparter i försäkringsavtalet och försäkrad
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-1">
            <div class="col mb-3">
              <FormField
                :field="formList.insurance_company"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.municipality"
                :values="selectOptions.municipalities"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.property_owner"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField :field="formList.management" @updatedValue="updated" />
            </div>
            <!--lägg in i titel-->
            <div class="col mb-1">
              <FormField :field="formList.id" />
            </div>
          </div>
        </div>

        <!--        SECOND ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-2"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-2"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Grundfakta om byggnaden
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-2">
            <div class="col mb-1">
              <FormField :field="formList.name" @updatedValue="updated" />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.external_id"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField :field="formList.group" @updatedValue="updated" />
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.other_responsible"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.other_responsible_email"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.property_designation"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.official_id"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.street_name"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-2 mb-1">
                <FormField
                  :field="formList.street_number"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-2 mb-1">
                <FormField :field="formList.zip_code" @updatedValue="updated" />
              </div>
              <div class="col-6 mb-1">
                <FormField :field="formList.city" @updatedValue="updated" />
              </div>
            </div>
            <div>
              <div class="form-row-inside">
                <div class="col-2 mb-1">
                  <FormField
                    :field="formList.coordinate_lat"
                    @updatedValue="updated"
                  />
                </div>
                <div class="col-2 mb-1" id="hamta">
                  <FormField
                    :field="formList.coordinate_lon"
                    @updatedValue="updated"
                  />
                </div>
                <a class="btn btn-primary" id="hittaKoordinator">
                  Hämta Koordinater Från Karta
                </a>
              </div>
              <div class="map-container">
                <maptest class="createMap" :coord_in="coord"></maptest>
              </div>
            </div>
          </div>
        </div>

        <!--        THIRD ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-3"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-3"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Intern information hos kommunen
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-3">
            <div class="col-12 mb-1">
              <FormField
                :field="formList.external_note"
                @updatedValue="updated"
              />
            </div>
            <div class="col-12 mb-1">
              <FormField
                :field="formList.internal_municipality_id"
                @updatedValue="updated"
              />
            </div>
          </div>
        </div>

        <!--        FOURTH ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-4"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-4"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Riskupplysningar om byggnaden
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-4">
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.category_external"
                  :values="selectOptions.category_external"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1" v-if="isIntern">
                <FormField
                  :field="formList.category_internal"
                  :values="selectOptions.category_internal"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.specification"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField :field="formList.tenant" @updatedValue="updated" />
            </div>
            <div class="col-4 mb-1">
              <FormField
                :field="formList.gross_floor_area"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.basement_included"
                  :values="selectOptions.yes_no"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.number_of_floors"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.build_year"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.latest_renovation"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.facility_class"
                :values="selectOptions.facility_class"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.cultural_protection_type"
                :values="selectOptions.cultural_protection_type"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.solar_cells"
                  :values="selectOptions.solar_cells"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.energy_storage"
                  :values="selectOptions.yes_no"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.fire_alarm"
                  :values="selectOptions.fire_alarm"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.fire_protection"
                  :values="selectOptions.fire_protection"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.burglar_alarm"
                  :values="selectOptions.burglar_alarm"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.burglar_alarm_type"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col mb-1 input-group">
                <FormField
                  :field="formList.insurance_company_information"
                  @updatedValue="updated"
                />
              </div>
            </div>
          </div>
        </div>

        <!--        FIFTH ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-5"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-5"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Egenkontroll och besiktning - Starkström
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-5">
            <div class="col mb-1">
              <FormField
                :field="formList.electrical_id"
                @updatedValue="updated"
              />
            </div>
          </div>
        </div>

        <!--        SIXTH ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-6"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-6"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Byggnadens försäkringsskydd
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-6">
            <div class="col mb-1">
              <FormField
                :field="formList.requested_insurance_from_date"
                @updatedValue="updated"
              />
            </div>
            <div
              class="col mb-1"
              v-if="formList.requested_form_of_insurance.isVisible(ctx)"
            >
              <FormField
                :field="formList.requested_form_of_insurance"
                :values="selectOptions.form_of_insurance"
                @updatedValue="updated"
              />
            </div>
            <div
              class="col mb-1"
              v-if="formList.form_of_insurance.isVisible(ctx)"
            >
              <FormField
                :field="formList.form_of_insurance"
                :values="selectOptions.form_of_insurance"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div
                class="col-6 mb-1"
                v-if="formList.insurance_amount.isVisible(ctx)"
              >
                <FormField
                  :field="formList.insurance_amount"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1" v-if="formList.first_risk.isVisible(ctx)">
                <FormField
                  :field="formList.first_risk"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.calculation_method"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.calculated_value"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.calculation_date"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1" v-if="isIntern">
              <FormField
                :field="formList.calculated_value_content"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1" v-if="isIntern">
              <FormField
                :field="formList.calculated_value_total"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.specific_terms"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.insurance_from_date"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.requested_insurance_to_date"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.insurance_to_date"
                @updatedValue="updated"
              />
            </div>
          </div>
        </div>

        <!--        SEVENTH ROW OF FIELDS      -->

        <div class="section" v-if="isIntern">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-7"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-7"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Intern information hos försäkringsbolaget
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-7">
            <div class="col mb-1">
              <FormField :field="formList.premium" @updatedValue="updated" />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.adjusted_premium"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row">
              <div class="col">
                <FormField
                  :field="formList.internal_note"
                  @updatedValue="updated"
                />
              </div>
            </div>
          </div>
        </div>

        <!--        EIGHT ROW OF FIELDS                   -->

        <div class="section align-items-baseline" v-if="isIntern">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-8"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-8"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Senaste ändring i kommunobjekt.se
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-8">
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.created_by"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.created_on"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1" v-if="isIntern">
                <FormField :field="formList.uw" @updatedValue="updated" />
              </div>
              <div class="col-6 mb-1" v-if="isIntern">
                <FormField
                  :field="formList.uw_decision_date"
                  @updatedValue="updated"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <footer-container
      :isNew="false"
      :hasPendingChanges="pendingChanges"
      :isUpdate="false"
      :isApprove="true"
      :isModified="noOfChanges > 0"
      :hasIntermediary="!!itemIntermediary"
      :isValid="isValid"
      @doValidation="prepAndValidate"
      @execute="doApprove"
      @goToUpdate="goToUpdate"
    />
  </div>
</template>

<script>
import Base from "@/views/InsuranceInputBase.vue";
import FooterContainer from "../components/FooterContainer.vue";
import { validateInputMixin } from "@/components/mixins/validate-input.mixin";
import { axiosBase } from "@/axios-api";
import maptest from "@/components/maptest";
import { useToast } from "vue-toastification";
import FormField from "@/components/form/FormField.vue";
import { insuranceObjectTemplate } from "../components/InsuranceObjectTemplate";
import _ from "lodash";

export default {
  extends: Base,
  components: {
    maptest,
    FormField,
    FooterContainer,
  },
  mixins: [validateInputMixin],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      changeMaster: 0,
      changeMasterStatus: "",
      pendingChanges: false,
      item: _.cloneDeep(insuranceObjectTemplate),
      components: {
        maptest,
      },
    };
  },
  mounted() {
    this.initBase();
    this.initialize();
    this.changeHandler.setUser(this.user);
    this.changeHandler.handleEvent("initialize", this.formList);
  },
  computed: {
    ctx: function () {
      return {
        new: false,
        notApproved: true,
        isIntern: this.isIntern,
        item: this.item,
        hasFormOfInsurance: this.hasFormOfInsurance,
        formOfInsurance: this.formOfInsurance,
      };
    },
    isIntern: function () {
      return this.user.roles.kommunassurans;
    },
    title: function () {
      if (this.formList.id.value)
        return this.formList.id.value + " - " + this.formList.name.value;
      return " ";
    },
  },
  methods: {
    goToUpdate() {
      this.$router.push({ name: "Update" });
    },
    hasSelectedOptionKey(field, key) {
      const currentId = this.submitForm[field] ?? this.formList[field].value;
      if (currentId > 0)
        return this.attributes.find((x) => x.value == currentId).key === key;
      return false;
    },
    prepAndValidate(doWarning) {
      this.ensureDefaultValuesAreSubmitted();
      this.isValid = this.validate(doWarning);
    },
    async doApprove() {
      if (this.byIntermediary) {
        this.submitForm["intermediary"] = this.itemIntermediary;
      }
      const props = {
        id: this.$route.params.id,
        sendEmail: this.isIntern,
        successMessage: this.getSuccessToastMessage(),
      };
      await this.sendRequest(props);
    },
    getSuccessToastMessage() {
      if (this.isIntern) return "Ändringarna genomförda";
      else return "Ändringar godkända av Förmedlare och skickade till Admin";
    },
    initializePendingChanges() {
      if (this.item.changemaster.length) {
        this.pendingChanges = true;
        const cm = this.item.changemaster[0];
        this.changeMasterStatus = cm.status;
        this.changeMaster = cm.id;
        for (let i = 0; i < cm.changelog.length; i++)
          this.formList[cm.changelog[i].field].awaitingApproval = true;
        for (let key in this.formList) this.formList[key].locked = true;
      }
    },
    checkItemAccess() {
      if (!this.pendingChanges) {
        this.toast.warning("Inga förändringar att godkänna");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      } else if (this.user.roles.municipality) {
        this.toast.error("Detta objekt väntar på godkännande");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      } else if (
        this.user.roles.intermediary &&
        this.changeMasterStatus === "at_admin"
      ) {
        this.toast.error("Du har ej behörighet att godkänna ändringarna");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      }
    },
    async loadItem() {
      await axiosBase
        .get(`api/v1/insuranceobject/${this.$route.params.id}/`)
        .then((response) => {
          this.item = response.data;
          this.specific_terms = response.data["specific_terms"];
          this.insurance_company_information =
            response.data["insurance_company_information"];
          this.item.specific_terms = "";
          this.item.insurance_company_information = "";
          this.coord = {
            lat: this.item.coordinate_lat ? this.item.coordinate_lat : 0,
            lon: this.item.coordinate_lon ? this.item.coordinate_lon : 0,
          };
          for (let key in this.formList) {
            if (this.item[key]) {
              if (this.item[key].username)
                this.item[key] = this.item[key].username;
              else if (this.item[key].id)
                this.item[key] = this.item[key].id; // Sets the FK Id as value
              else if (this.formList[key].type === "date")
                this.item[key] = this.item[key].substring(0, 10);
              this.formList[key].value = this.item[key];
            } else {
              this.item[key] = this.formList[key].type === "date" ? null : "";
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.toast.error("Efterfrågat objekt saknas.");
            this.$router.push({
              name: "Main",
            });
          }
        });
    },
    async initialize() {
      this.coord = { lat: 0, lon: 0 };
      this.getLabels();
      await this.getMunicipalities();
      this.getAttributes();
      await this.loadItem();
      this.initializePendingChanges();
      this.checkItemAccess();
    },
  },
};
</script>

<style>
#create {
  margin-left: 15px;
}

@media (min-width: 768px) {
  #edit {
    padding-right: 5px;
  }
}

@media (min-width: 1400px) {
  .multi-collapse {
    background-color: lightgreen;
  }
}
div.ol-unselectable ol-layers {
  margin-top: 22px;
}
div.ol-zoom ol-unselectable ol-control {
  margin-top: 22px;
}
.gul {
  padding-right: 20px;
  margin-left: 17%;
}
b#changelock.gul {
  margin-left: 102px;
}
select#intermediary.form-Mottagare {
  margin-top: 10px;
}

.grön {
  /*padding-left: 30%;*/
  padding-right: 20px;
}

.btn,
.btn-success {
  height: 38px;
  margin-top: 4px;
}

div.form-check.form-Mottagare {
  padding: 0 !important;
  margin-left: 30px;
}

#hittaKoordinator {
  margin-top: 32px;
}

.card-body {
  width: 50%;
}

.table > :not(caption) > * > * {
  padding: 0.3rem 0.3rem 0.3rem !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-right: 2px;
}

.form-select {
  width: 100% !important;
  overflow: auto;
}

.multi-collapse {
  overflow: auto;
}

.btn-icon {
  height: 14px;
  margin: 0 auto;
  margin-top: -3px;
}

.form-row > .form-row [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-row-inside {
  display: flex;
  flex-direction: row;
}

/*knapp till kommuner */
#edit {
  margin: 10px 0 10px 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.crateContainer {
  border-top: 1px solid #ced4da;
  background-color: white;
  position: sticky;
  bottom: 0;
}

.change-button-container {
  display: flex;
  justify-content: space-between;
}

.form-row {
  margin: 0 auto;
  display: flex;
}

div.form-row {
  margin-bottom: 0rem;
  padding: 0.375rem 0.75rem;
}
.Form-control {
  margin-top: 0 !important;
  margin-bottom: 15px;
  width: 100% !important;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
}

.input-group-text {
  background-color: #e9ecef;
  border: 0 solid #ced4da !important;
  border-radius: 0.25rem;
}

.card-edit {
  max-width: 35%;
  padding-right: 12px;
  margin-bottom: 40px;
  padding-top: 14px;
}

.map-container #map {
  margin: 16px 0 0 0;
  height: 300px;
}
</style>

<style scoped>
.section {
  border-radius: 6px;
  margin-bottom: 15px;
}

.section-header {
  border-bottom: 1px solid rgb(255, 255, 255);
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  background-color: #cbe5e8;
  font-size: 1.4rem;
}

.section-title {
  margin-top: 0 !important;
  padding: 15px;
  color: rgb(89, 89, 89);
  font-size: 1.5rem;
}

.section-body {
  padding: 15px;
  background-color: #e1eff0;
  border-radius: 0 0 6px 6px;
}
.section-header[aria-expanded="true"] .fa-plus {
  display: none;
}
.section-header[aria-expanded="false"] .fa-minus {
  display: none;
}

.form-check {
  padding-left: 5px;
}

label {
  font-weight: 600;
  color: #0176ba;
}

.ol-viewport {
  margin-top: -25px;
}
.map-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 300px;
  margin-bottom: 28px;
}

.change-button-container {
  height: 95px;
  margin: 0;
  align-items: center;
  width: 100%;
}

.close-container-button[aria-expanded="false"] {
  display: none;
}

.close-container-button {
  font-weight: 400;
  background-color: white;
  font-weight: bold;
  border: none;
  color: #0176ba;
  margin-right: 30px;
}

.close-container-button:focus,
.close-container-button:hover,
.close-container-button:active {
  background-color: white;
  color: #0176ba;
}

#locked {
  display: flex;
  width: 100%;
  justify-content: center;
}

#create,
#edit {
  margin-left: 30px;
}

.form-Mortar {
  border: none;
  margin-left: 10px;
}
</style>
