<template>
  <div class="dashboard">
    <Sidebar />

    <div class="content">
      <b-container>
        <b-row>
          <b-col>
            <b-row>
              <select
                id="intermery"
                class="interCLass"
                v-model="selected"
                @change="onChange($event)"
              >
                <option disabled value=""></option>
                <option
                  class="buttonOne"
                  v-for="intermediary in intermediaries"
                  v-bind:key="intermediary.id"
                  v-bind:value="intermediary.id"
                >
                  {{ intermediary.name }}
                </option>
              </select>

              <p class="kommun">
                <a
                  id="kommun"
                  class="btn btn-primary"
                  data-bs-toggle="collapse"
                  href="#formedlarhusToggle"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                  >Lägg till förmedlarhus</a
                >
              </p>
              <div class="collapse multi-collapse" id="formedlarhusToggle">
                <div class="card card-body" id="formedlarhusToggle">
                  <form @submit.prevent="submitForm">
                    <div class="form-group">
                      <label for="interName">Namn</label>
                      <input
                        type="text"
                        class="Form-controls"
                        id="interName"
                        placeholder="Namn"
                        v-model="intermediaryName"
                      />
                    </div>
                    <div class="form-group">
                      <label for="interOrgNo">Org Nummmer</label>
                      <input
                        type="text"
                        class="Form-controls"
                        id="interOrgNo"
                        placeholder="Org"
                        v-model="intermediaryOrgNo"
                      />
                    </div>
                    <button type="submit" class="btn btn-primary">
                      Lägg till
                    </button>
                  </form>
                </div>
              </div>
            </b-row>
          </b-col>
          <b-col>
            <b-row v-if="displayContent">
              <b-col>
                <form @submit.prevent="addMunicipality">
                  <!--              Municipalities-->
                  <select v-model="selectedMunicipality" class="interCLass">
                    <option disabled value=""></option>
                    <option
                      class="buttonOne"
                      v-for="municipality in municipalities"
                      v-bind:key="municipality.id"
                      v-bind:value="municipality.id"
                    >
                      {{ municipality.name }}
                    </option>
                  </select>
                  <button
                    id="buttonAddMunicipality"
                    type="submit"
                    class="btn btn-primary"
                  >
                    Lägg till kommun
                  </button>
                </form>
              </b-col>
              <b-col>
                <b-form @submit.prevent="addUser">
                  <!--              Users-->
                  <select v-model="selectedUser" class="interCLass">
                    <option disabled value=""></option>
                    <option
                      class="buttontwo"
                      v-for="user in users"
                      v-bind:key="user.id"
                      v-bind:value="user.id"
                    >
                      {{ user.username }}
                    </option>
                  </select>
                  <button
                    id="buttonAddUser"
                    type="submit"
                    class="btn btn-primary"
                  >
                    Lägg till användare
                  </button>
                </b-form>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="displayContent">
          <b-col>
            <b-row id="interAdin">
              <div class="span3">
                <table class="table table-striped table-hover table.bordered">
                  <thead>
                    <tr class="header" scope="col">
                      <th scope="col">Namn</th>
                      <th scope="col">Org Nummmer</th>
                      <th class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="interMunicipality in interMunicipalities"
                      v-bind:key="interMunicipality.id"
                    >
                      <td>{{ interMunicipality.name }}</td>
                      <td>{{ interMunicipality.org_no }}</td>

                      <td class="text-right">
                        <button
                          @click="deleteMunicipality(interMunicipality.id)"
                          class="btn btn-danger badge bg-danger float-right"
                          style="width: 80px"
                        >
                          <i class="fas fa-trash-alt"></i>
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-row>
          </b-col>
          <b-col>
            <b-row id="interAdin2">
              <div class="span3">
                <table class="table table-striped table-hover table.bordered">
                  <thead>
                    <tr class="header" scope="col">
                      <th scope="col">Email</th>
                      <th scope="col">Namn</th>
                      <th class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="interUser in interUsers"
                      v-bind:key="interUser.id"
                    >
                      <td>{{ interUser.username }}</td>
                      <td>
                        {{ interUser.first_name + " " + interUser.last_name }}
                      </td>

                      <td class="text-right">
                        <button
                          @click="deleteUser(interUser.id)"
                          class="btn btn-danger badge bg-danger float-right"
                          style="width: 80px"
                        >
                          <i class="fas fa-trash-alt"></i>
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import { axiosBase } from "@/axios-api";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "Attributes",
  components: {
    Sidebar,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      intermediaries: [],
      selected: null,
      interMunicipalities: [],
      interUsers: [],
      intermediaryName: "",
      intermediaryOrgNo: "",
      municipalities: [],
      users: [],
      selectedMunicipality: "",
      selectedUser: "",
    };
  },
  computed: {
    displayContent: function () {
      return !!this.selected;
    },
  },
  created() {
    this.getIntermediaries();
    this.getSelectData();
  },
  methods: {
    addMunicipality() {
      this.editIntermediary({
        municipality: this.selectedMunicipality,
      });
    },
    addUser() {
      this.editIntermediary({ user: this.selectedUser });
    },
    deleteMunicipality(id) {
      this.editIntermediary({ municipality: id, destroy: true });
    },
    deleteUser(id) {
      this.editIntermediary({ user: id, destroy: true });
    },
    async editIntermediary(data) {
      await axiosBase
        .patch(`api/v1/intermediary/${this.selected}/`, data)
        .then((response) => {
          if (data.destroy) {
            this.toast.success(
              "Användare/Kommun togs bort från " + response.data.name + "!"
            );
          } else {
            this.toast.success(
              "Användare/Kommun lades till " + response.data.name + "!"
            );
          }
          this.onChange();
          this.getSelectData();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            // let message = "";
            //TODO funkar inte atm
            this.toast.error(error.response.data.detail);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Something went wrong");
          }
        });
    },
    async submitForm() {
      const intermediary = {
        name: this.intermediaryName,
        org_no: this.intermediaryOrgNo,
      };
      await axiosBase
        .post("api/v1/intermediary/", intermediary)
        .then(() => {
          this.toast.success(
            "Förmedlarhus " + this.intermediaryName + " skapades!"
          );
          this.getIntermediaries();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            this.toast.error(message);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Something went wrong");
          }
        });
    },
    async getIntermediaries() {
      await axiosBase
        .get("api/v1/intermediary/")
        .then((response) => {
          this.intermediaries = response.data;
        })
        .catch(() => {});
    },
    async getSelectData() {
      // Run in parallel
      await axios
        .all([
          axiosBase.get(`api/v1/municipality/`),
          axiosBase.get(`auth/users/`),
        ])
        .then(
          axios.spread((data1, data2) => {
            // output of req.
            this.municipalities = data1.data.filter(function (obj) {
              return (
                !Array.isArray(obj.intermediaries) || !obj.intermediaries.length
              );
            });
            this.users = data2.data.filter(function (obj) {
              return (
                obj.group.name === "INTERMEDIARY" &&
                obj.has_intermediary === false
              );
            });
          })
        )
        .catch(() => {});
    },
    async onChange() {
      // Run in parallel

      await axiosBase
        .get(`api/v1/intermediary/${this.selected}/`)
        .then((response) => {
          // output of req.
          this.interMunicipalities = response.data.municipalities;
          this.interUsers = response.data.users;
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            this.toast.error(message);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Something went wrong");
          }
        });
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .buttontwo {
    margin-right: 55px !important;
  }

  p.kommun {
    padding: 0;
    margin-left: 13px !important;
  }
  select#intermery {
    width: 50%;
    margin-left: 13px;
    padding-left: 8px;
    margin-top: 40px;
  }
  .dashboard {
    height: 168vh;
  }
}

@media (min-width: 1400px) {
  p.kommun {
    padding: 0;
    margin-left: 13px !important;
  }
  select#intermery {
    width: 50%;
    margin-left: 13px;
    padding-left: 8px;
    margin-top: 40px;
  }
  .dashboard {
    height: 108vh;
  }
}
div.span3 {
  height: 500px !important;
  overflow: scroll;
  margin-top: 24px;
  padding-right: 1px;
  margin-left: -4px;
}

#formedlarhusToggle {
  margin-left: 7px;
}
.interCLass {
  width: 82%;
}

.header {
  position: sticky;
  z-index: 3;
  color: white;
  top: -2px;
  font-size: 15px;
}

div#multi-collapse.collapse.show #formedlarhusToggle {
  width: 50%;
}

div#formedlarhusToggle.card.card-body {
  width: 50%;
}

#interAdin {
  margin-left: 8px;
  margin-right: 18px;
}

#interAdin2 {
  margin-right: 18px;
}

#intermery {
  width: 80%;
  padding-left: 70px;
}

select.interCLass {
  margin-top: 40px;
}

a#kommun.btn.btn-primary {
  margin-right: 72px !important;
  margin-left: 0;
}

button#buttonAddMunicipality.btn.btn-primary {
  margin-left: 0;
}

button#buttonAddUser.btn.btn-primary {
  margin-left: 0;
}

.col-md-8 {
  display: inline-flex;
}

.col-md-20 {
  display: flex;
}

.form-select {
  width: 30% !important;
  /* margin-left: 23px; */
}

.btn-secondary {
  color: #fff;
  background-color: #0176ba !important;
}

.btn .btn-primary {
  margin-left: 0.3rem !important;
}

.card-bodys {
  max-width: 100%;
}

label {
  padding-bottom: 5px;
}

.card {
  padding-left: 2rem !important;
}

.form-group {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
</style>
