<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
  },
  data() {
    return {
      type: "date"
    };
  },
  mounted() {
    this.setFieldType();
  },
  methods: {
    setFieldType() {
      if(!this.field.value)
        this.type = "text";
    },
    onBlur(e) {
      this.setFieldType();
    },
    onFocus(e) {
      this.type = "date";
    },
    onChange(e) {
      let value = e.target.value;
      if (value === "") value = null;
      this.field.value = value;
      this.$emit('updatedValue', this.field.value);
    },
  },
  emits: ["updatedValue"]
};
</script>
<template>
  <input
    max="9999-12-31"
    v-model="field.value"
    autocomplete="off"
    :type="type"
    v-bind:class="{ 
      'form-control': true,
      'is-invalid': !!field.invalid,
      'updated': !!field.updated,
      'awaiting-approval': !!field.awaitingApproval
    }"
    :id="field.name"
    :placeholder="field.placeholder"
    :disabled="field.disable || field.locked"
    :required="field.required"
    @focus="onFocus"
    @blur="onBlur"
    @change="onChange"
  />
</template>
