import { createRouter, createWebHistory } from "vue-router";
import Main from "@/views/Main";
import Login from "../views/Login.vue";
import Details from "../views/Details";

import Admin from "../views/Admin";
import Inbox from "../views/Inbox.vue";
import Municipality from "../views/Municipality.vue";
import PropertyOwner from "../views/PropertyOwner.vue";
import Users from "../views/Users.vue";
import Intermediary from "../views/Intermediary";
import Attributes from "../views/Attributes.vue";
import Create from "../views/Create";
import Update from "../views/Update.vue";
import Approve from "../views/Approve.vue";
import Password from "../views/Password";
import ResetPasswordConfirm from "../views/ResetPasswordConfirm";
import ResetPassword from "@/views/ResetPassword";
import ActivateAccount from "@/views/ActivateAccount";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: "Logga in" },
  },
  {
    path: "/pass",
    name: "Password",
    component: Password,
    meta: { title: "Administrera Lösenord", requiresLogin: true },
  },
  {
    path: "/account/password_reset/confirm/:uid/:token",
    name: "ResetPasswordConfirm",
    component: ResetPasswordConfirm,
    meta: { title: "Bekräfta nytt lösenord" },
  },
  {
    path: "/account/password_reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { title: "Begär nytt lösenord" },
  },
  {
    path: "/account/activate/:uid/:token",
    name: "ActivateAccount",
    component: ActivateAccount,
    meta: { title: "Aktivera konto" },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresLogin: true,
      requiresAdmin: true,
      title: "Admin",
    },
  },
  {
    path: "/main/create",
    name: "Create",
    component: Create,
    meta: {
      requiresLogin: true,
      requiresEdit: true,
      title: "Create Object",
    },
  },
  {
    path: "/admin/inbox",
    name: "Inbox",
    component: Inbox,
    meta: {
      requiresLogin: true,
      title: "Inkorg",
    },
  },
  {
    path: "/admin/municipality",
    name: "Municipality",
    component: Municipality,
    meta: {
      requiresLogin: true,
      title: "Hantera Kommuner",
    },
  },
  {
    path: "/propertyowner",
    name: "PropertyOwner",
    component: PropertyOwner,
    meta: {
      requiresLogin: true,
      title: "Hantera Fastighetsägare",
    },
  },
  {
    path: "/admin/users",
    name: "Users",
    component: Users,
    meta: {
      requiresLogin: true,
      title: "Användare",
    },
  },

  {
    path: "/admin/attributes",
    name: "Attributes",
    component: Attributes,
    meta: {
      requiresLogin: true,
      title: "Hantera optionslistor",
    },
  },
  {
    path: "/admin/intermediary",
    name: "Intermediary",
    component: Intermediary,
    meta: {
      requiresLogin: true,
      title: "Hantera Förmedlarhus",
    },
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    meta: {
      requiresLogin: true,
      title: "Main",
    },
  },
  {
    path: "/main/:id",
    name: "Details",
    component: Details,
    meta: {
      requiresLogin: true,
      title: "Object Details",
    },
  },
  {
    path: "/main/:id/update",
    name: "Update",
    component: Update,
    meta: {
      requiresLogin: true,
      requiresEdit: true,
      title: "Update Object",
    },
  },
  {
    path: "/main/:id/approve",
    name: "Approve",
    component: Approve,
    meta: {
      requiresLogin: true,
      requiresEdit: true,
      title: "Approve request",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});
export default router;
