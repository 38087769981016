<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
  },
  emits: ["updatedValue"],
  methods: {
    onChange(e) {
      let value = e.target.value;
      if (value === "") value = null;
      if (!isNaN(value)) value = Number(value);
      this.field.value = value;
      this.$emit('updatedValue', this.field.value);
    },
  },
};
</script>
<template>
  <input
    v-model="field.value"
    type="number"
    autocomplete="off"
    :max="field.max ? field.max : ''"
    :min="field.min ? field.min : ''"
    v-bind:class="{ 
      'form-control': true,
      'is-invalid': !!field.invalid,
      'updated': !!field.updated,
      'awaiting-approval': !!field.awaitingApproval
    }"
    :id="field.name"
    :placeholder="field.placeholder"
    :disabled="field.disable || field.locked"
    :required="field.required"
    @change="onChange"
  />
</template>