import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue3 from "bootstrap-vue-3";
import Toast, { TYPE, POSITION } from "vue-toastification";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-toastification/dist/index.css";
import Helpers from "./helpers";

const options = {
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      timeout: 5000,
      closeButton: true,
    },
    [TYPE.SUCCESS]: {
      timeout: 3000,
      hideProgressBar: false,
    },
    [TYPE.INFO]: {
      timeout: 5000,
      hideProgressBar: false,
    },
  },
};

createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .use(Toast, {
    options,
    position: POSITION.BOTTOM_RIGHT,
    transition: "Vue-Toastification__bounce",
    maxToasts: 2,
    newestOnTop: true,
  })
  .use(Helpers)
  .mount("#app");

router.beforeEach(async (to, from, next) => {
  /** Initial check if user is logged in */

  if (to.matched.some((record) => record.meta.requiresLogin)) {
    let isLoggedIn = await store.dispatch("isLoggedIn");
    if (!isLoggedIn) {
      next({ name: "Login" });
    } else {
      let user = await store.getters.userDetail;
      /** Secondary check if user is Admin to access requiresAdmin pages */

      if (to.matched.some((record) => record.meta.requiresAdmin)) {
        if (user.isAdmin === "false") {
          next({ name: "Main" });
        } else {
          next();
        }
      } /** Secondary check if user can Edit to access requiresEdit pages */ else if (
        to.matched.some((record) => record.meta.requiresEdit)
      ) {
        if (user.canEdit === null) {
          next({ name: "Main" });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
