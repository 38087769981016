<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
  },
  emits: ["updatedValue"]
};
</script>
<template>
  <div class="posting bold">{{ field.subTitle }}</div>
  <textarea
    v-model="field.value"
    :id="field.name"
    :disabled="field.disable || field.locked"
    :rows="field.rows ? field.rows : 2"
    v-bind:class="{
      'is-invalid': !!field.invalid,
      'updated': !!field.updated,
      'awaiting-approval': !!field.awaitingApproval
    }"
    @change="$emit('updatedValue', $event.target.value)"
  >
  </textarea>
  <div
    v-for="post in field.posts"
    v-bind:key="post.id"
    class="posting"
  >
    <i
      >[{{ post.created_by.username }} {{ post.created_on }}]</i
    >
    <p>{{ post.text }}</p>
  </div>
</template>

<style scoped>
.bold {
  font-weight: bold;
}
.posting,
.posting * {
  width: 100%;
}
textarea {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
</style>