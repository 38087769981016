<template>
  <div class="page-container">
    <div class="page-title">
      <h1>Skapa nytt objekt</h1>
    </div>
    <div class="col-xs-6 form-background">
      <form @submit.prevent class="needs-validation" novalidate>
        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-1"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-1"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Avtalsparter i försäkringsavtalet och försäkrad
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-1">
            <div class="col mb-3">
              <FormField
                :field="formList.insurance_company"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.municipality"
                :values="selectOptions.municipalities"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.property_owner"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField :field="formList.management" @updatedValue="updated" />
            </div>
            <!--lägg in i titel-->
            <div class="col mb-1">
              <FormField :field="formList.id" />
            </div>
          </div>
        </div>

        <!--        SECOND ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-2"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-2"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Grundfakta om byggnaden
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-2">
            <div class="col mb-1">
              <FormField :field="formList.name" @updatedValue="updated" />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.external_id"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField :field="formList.group" @updatedValue="updated" />
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.other_responsible"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.other_responsible_email"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.property_designation"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.official_id"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.street_name"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-2 mb-1">
                <FormField
                  :field="formList.street_number"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-2 mb-1">
                <FormField :field="formList.zip_code" @updatedValue="updated" />
              </div>
              <div class="col-6 mb-1">
                <FormField :field="formList.city" @updatedValue="updated" />
              </div>
            </div>
            <div>
              <div class="form-row-inside">
                <div class="col-2 mb-1">
                  <FormField
                    :field="formList.coordinate_lat"
                    @updatedValue="updated"
                  />
                </div>
                <div class="col-2 mb-1">
                  <FormField
                    :field="formList.coordinate_lon"
                    @updatedValue="updated"
                  />
                </div>
                <div class="col-3 mb-1 bottom">
                  <a class="btn btn-primary" @click="updateCoordinates()">
                    Hämta Koordinater Från Karta
                  </a>
                </div>
              </div>
              <div class="map-container">
                <maptest
                  class="createMap"
                  :coord_in="coord"
                  :enableClick="true"
                  @new_coord="getCoordinates"
                ></maptest>
              </div>
            </div>
          </div>
        </div>

        <!--        THIRD ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-3"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-3"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Intern information hos kommunen
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-3">
            <div class="col-12 mb-1">
              <FormField
                :field="formList.external_note"
                @updatedValue="updated"
              />
            </div>
            <div class="col-12 mb-1">
              <FormField
                :field="formList.internal_municipality_id"
                @updatedValue="updated"
              />
            </div>
          </div>
        </div>

        <!--        FOURTH ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-4"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-4"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Riskupplysningar om byggnaden
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-4">
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.category_external"
                  :values="selectOptions.category_external"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1" v-if="isIntern">
                <FormField
                  :field="formList.category_internal"
                  :values="selectOptions.category_internal"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.specification"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField :field="formList.tenant" @updatedValue="updated" />
            </div>
            <div class="col-4 mb-1">
              <FormField
                :field="formList.gross_floor_area"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.basement_included"
                  :values="selectOptions.yes_no"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.number_of_floors"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.build_year"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.latest_renovation"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.facility_class"
                :values="selectOptions.facility_class"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.cultural_protection_type"
                :values="selectOptions.cultural_protection_type"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.solar_cells"
                  :values="selectOptions.solar_cells"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.energy_storage"
                  :values="selectOptions.yes_no"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.fire_alarm"
                  :values="selectOptions.fire_alarm"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.fire_protection"
                  :values="selectOptions.fire_protection"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.burglar_alarm"
                  :values="selectOptions.burglar_alarm"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.burglar_alarm_type"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col mb-1">
                <FormField
                  :field="formList.insurance_company_information"
                  @updatedValue="updated"
                />
              </div>
            </div>
          </div>
        </div>

        <!--        FIFTH ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-5"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-5"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Egenkontroll och besiktning - Starkström
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-5">
            <div class="col mb-1">
              <FormField
                :field="formList.electrical_id"
                @updatedValue="updated"
              />
            </div>
          </div>
        </div>

        <!--        SIXTH ROW OF FIELDS       -->

        <div class="section">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-6"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-6"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Byggnadens försäkringsskydd
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-6">
            <div class="col mb-1">
              <FormField
                :field="formList.requested_insurance_from_date"
                @updatedValue="updated"
              />
            </div>
            <div
              class="col mb-1"
              v-if="formList.requested_form_of_insurance.isVisible(ctx)"
            >
              <FormField
                :field="formList.requested_form_of_insurance"
                :values="selectOptions.form_of_insurance"
                @updatedValue="updated"
              />
            </div>
            <div
              class="col mb-1"
              v-if="formList.form_of_insurance.isVisible(ctx)"
            >
              <FormField
                :field="formList.form_of_insurance"
                :values="selectOptions.form_of_insurance"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row-inside">
              <div
                class="col-6 mb-1"
                v-if="formList.insurance_amount.isVisible(ctx)"
              >
                <FormField
                  :field="formList.insurance_amount"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1" v-if="formList.first_risk.isVisible(ctx)">
                <FormField
                  :field="formList.first_risk"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.calculation_method"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.calculated_value"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-4 mb-1">
                <FormField
                  :field="formList.calculation_date"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="col mb-1" v-if="isIntern">
              <FormField
                :field="formList.calculated_value_content"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1" v-if="isIntern">
              <FormField
                :field="formList.calculated_value_total"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1" v-if="isIntern">
              <FormField
                :field="formList.specific_terms"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.insurance_from_date"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.requested_insurance_to_date"
                @updatedValue="updated"
              />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.insurance_to_date"
                @updatedValue="updated"
              />
            </div>
          </div>
        </div>

        <!--        SEVENTH ROW OF FIELDS      -->

        <div class="section" v-if="isIntern">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-7"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-7"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Intern information hos försäkringsbolaget
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-7">
            <div class="col mb-1">
              <FormField :field="formList.premium" @updatedValue="updated" />
            </div>
            <div class="col mb-1">
              <FormField
                :field="formList.adjusted_premium"
                @updatedValue="updated"
              />
            </div>
            <div class="form-row">
              <div class="col">
                <FormField
                  :field="formList.internal_note"
                  @updatedValue="updated"
                />
              </div>
            </div>
          </div>
        </div>

        <!--        EIGHT ROW OF FIELDS                   -->

        <div class="section align-items-baseline" v-if="isIntern">
          <div
            class="section-header"
            data-bs-toggle="collapse"
            href="#toggle-section-8"
            role="button"
            aria-expanded="true"
            aria-controls="toggle-section-8"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              Senaste ändring i kommunobjekt.se
            </h3>
          </div>
          <div class="section-body collapse show" id="toggle-section-8">
            <div class="form-row-inside">
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.created_by"
                  @updatedValue="updated"
                />
              </div>
              <div class="col-6 mb-1">
                <FormField
                  :field="formList.created_on"
                  @updatedValue="updated"
                />
              </div>
            </div>
            <div class="form-row-inside">
              <div class="col-6 mb-1" v-if="isIntern">
                <FormField :field="formList.uw" @updatedValue="updated" />
              </div>
              <div class="col-6 mb-1" v-if="isIntern">
                <FormField
                  :field="formList.uw_decision_date"
                  @updatedValue="updated"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <footer-container
      :isNew="true"
      :hasPendingChanges="false"
      :isUpdate="false"
      :isModified="false"
      :isApprove="false"
      :hasIntermediary="!!itemIntermediary"
      :isValid="isValid"
      @doValidation="prepAndValidate"
      @execute="doCreate"
    />
  </div>
</template>

<script>
import FooterContainer from "../components/FooterContainer.vue";
import Base from "@/views/InsuranceInputBase.vue";
import maptest from "@/components/maptest";
import FormField from "@/components/form/FormField.vue";

export default {
  extends: Base,
  components: {
    maptest,
    FormField,
    FooterContainer,
  },
  data() {
    return {
      new_coord: null,
      components: {
        maptest,
      },
    };
  },
  async mounted() {
    this.initBase();
    this.coord = { lat: 0, lon: 0 };
    this.getLabels();
    this.getAttributes();
    await this.getMunicipalities();
    this.setInitialMunicipality();
    this.changeHandler.setUser(this.user);
    this.changeHandler.handleEvent("initialize", this.formList);
  },
  computed: {
    ctx: function () {
      return {
        formOfInsurance: this.formOfInsurance,
        new: true,
        notApproved: false,
        isIntern: this.isIntern,
        item: this.item,
      };
    },
  },
  methods: {
    prepAndValidate(doWarning) {
      this.setIntermediary();
      this.ensureDefaultValuesAreSubmitted();
      this.isValid = this.validate(doWarning);
    },
    async doCreate(data) {
      if (data.byIntermediary) {
        this.submitForm["intermediary"] = this.itemIntermediary;
      }
      this.submitForm["type_of_change"] = data.typeOfChange;
      this.submitForm["object_type"] = 1; //make dynamic after "Byggnader"

      const props = {
        id: null,
        sendEmail: this.isIntern,
        successMessage: this.getSuccessToastMessage(data.byIntermediary),
      };

      await this.sendRequest(props);
    },
    getSuccessToastMessage(byIntermediary) {
      if (this.isIntern) return "Nytt objekt har skapat";
      else if (byIntermediary)
        return "Begäran om nytt objekt har skickats till Förmedlare";
      return "Begäran om nytt objekt har skickats till Admin";
    },
    setInitialMunicipality() {
      if (this.selectOptions.municipalities.length === 1) {
        this.formList["municipality"].value =
          this.selectOptions.municipalities[0].value;
        this.updatePropertyValue(
          "municipality",
          this.formList["municipality"].value
        );
      }
    },
  },
};
</script>

<style>
#create {
  margin-left: 15px;
}

@media (min-width: 1400px) {
  .multi-collapse {
    background-color: lightgreen;
  }
}
/*knapp*/

select#intermediary.form-Mottagare {
  margin-top: 10px;
}

.btn,
.btn-success {
  height: 38px;
  margin-top: 4px;
}

div#SkickaChangeFörmedlare.card.card-body {
  width: 100%;
  border: none;
  border-radius: 0;
}

div.form-check.form-Mottagare {
  padding: 0 !important;
  margin-left: 30px;
}

.bottom {
  margin-top: 28px;
}

.card-body {
  width: 50%;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-right: 2px;
}

.multi-collapse {
  overflow: auto;
}

.form-row > .form-row [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-row-inside {
  display: flex;
  flex-direction: row;
}

.form-row {
  margin-bottom: 0rem;
  padding: 0.375rem 0.75rem;
  display: flex;
}

.map-container #map {
  margin: 16px 0 0 0;
  height: 300px;
}
</style>

<style scoped>
.section {
  border-radius: 6px;
  margin-bottom: 15px;
}

.section-header {
  border-bottom: 1px solid rgb(255, 255, 255);
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  background-color: #cbe5e8;
  font-size: 1.4rem;
}

.section-title {
  margin-top: 0 !important;
  padding: 15px;
  color: rgb(89, 89, 89);
  font-size: 1.5rem;
}

.section-body {
  padding: 15px;
  background-color: #e1eff0;
  border-radius: 0 0 6px 6px;
}
.section-header[aria-expanded="true"] .fa-plus {
  display: none;
}
.section-header[aria-expanded="false"] .fa-minus {
  display: none;
}

.ol-viewport {
  margin-top: -25px;
}
.map-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 300px;
  margin-bottom: 28px;
}
.form-Mortar {
  border: none;
  margin-left: 10px;
}
</style>
