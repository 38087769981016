<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    realValue: {
      immediate: true,
      handler() {
        if (!this.value && this.realValue) {
          this.value = this.$helpers.returnCurrency(this.realValue);
        }
      },
    },
  },
  computed: {
    realValue(){
      return this.field.value;
    }
  },
  emits: ["updatedValue"],
  methods: {
    onBlur(e) {
      this.value = this.$helpers.returnCurrency(e.target.value);
    },
    onKeyPress(e) {
      let keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        e.preventDefault();
      }
    },
    onFocus(e) {
      this.value = this.getNumber(this.value);
    },
    getNumber(value) {
      console.log("vä r de ", value);
      if (value === null || value === "" || typeof value === "undefined")
        return null;
      return value
        .toString()
        .replace("kr", "")
        .replace(",", ".")
        .replace(/\s/g, "");
    },
    onChange(e) {
      let value = e.target.value;
      if (value === "") value = null;
      if (!isNaN(value)) value = Number(value);
      this.field.value = value;
      this.$emit("updatedValue", this.field.value);
    },
  },
};
</script>
<template>
  <input
    v-model="value"
    type="text"
    autocomplete="off"
    :maxlength="!!field.length ? field.length : ''"
    v-bind:class="{
      'form-control': true,
      'is-invalid': !!field.invalid,
      updated: !!field.updated,
      'awaiting-approval': !!field.awaitingApproval,
    }"
    :id="field.name"
    :placeholder="field.placeholder"
    :disabled="field.disable || field.locked"
    :required="field.required"
    @focus="onFocus"
    @blur="onBlur"
    @keypress="onKeyPress"
    @change="onChange"
  />
</template>
